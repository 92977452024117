import React from "react"
import { graphql } from "gatsby"
import { SEO } from "@yolo-seo"
import * as styles from "./blog-template.module.scss"

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
          social: {
            twitter: string
          }
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        date: string
        description: string
        author: string
        twitter: string
        locale: string
        imageTitle: string
      }
    }
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        twitter
        locale
        imageTitle
      }
    }
  }
`

const BlogTemplate: React.FCWithRoute<PageTemplateProps> = ({ data }) => {
  const blogTitle = data.site.siteMetadata.title
  const blogTwitter = data.markdownRemark.frontmatter.twitter
  const blogDescription = data.markdownRemark.frontmatter.description
  const blogLocale = data.markdownRemark.frontmatter.locale
  const blogAuthor = data.markdownRemark.frontmatter.author
  const blogUrl = ""
  const blogImageTitle = data.markdownRemark.frontmatter.imageTitle

  const metaData = [
    {
      name: "title",
      content: blogTitle,
    },
    {
      name: "description",
      content: blogDescription,
    },
  ]

  return (
    <div className={styles.root}>
      <SEO
        title={blogTitle}
        description={blogDescription}
        author={blogAuthor}
        twitter={blogTwitter}
        locale={blogLocale}
        imageTitle={blogImageTitle}
        url={blogUrl}
        meta={[...metaData]}
      ></SEO>
      <h1>{data?.markdownRemark.frontmatter.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: data?.markdownRemark.html || "" }}
      ></div>
    </div>
  )
}

export default BlogTemplate
